<template>
  <div>
    <div class="d-flex justify-content-center" v-if="carregando">
      <v-progress-circular indeterminate size="32" color="#356a7c" width="2"
        ><v-progress-circular
          indeterminate
          size="24"
          color="#ef720f"
          width="2"
          rotate="180"
        ></v-progress-circular
      ></v-progress-circular>
    </div>

    <b-form ref="frmConfiguracao" v-if="!carregando">
      <b-form-group>
        <label>Cliente<span class="text-danger">*</span>:</label>
        <div class="input-group">
          <pv-dropdown
            class="form-control"
            optionLabel="text"
            emptyFilterMessage="Nenhum resultado"
            :filter="true"
            :options="sltCliente"
            v-model="defaultsSelect.cliente"
            @input="obterCliente"
            appendTo="mdlConfiguracaoId"
          ></pv-dropdown>

          <div class="input-group-append">
            <button
              class="btn btn-primary btn-icon"
              type="button"
              @click="abrirMdlCadastrarCliente"
            >
              <i class="fa fa-plus"></i>
            </button>
          </div>
        </div>
      </b-form-group>

      <div class="row">
        <div class="col-6">
          <b-form-group>
            <label>Nome do cliente<span class="text-danger">*</span>:</label>
            <b-form-input
              name="nome"
              v-model="frmConfiguracao.nome"
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-3">
          <b-form-group>
            <label>Franquia mínima<span class="text-danger">*</span>:</label>
            <b-form-input
              name="franquiaMinima"
              v-model="frmConfiguracao.franquiaMinima"
              data-inputmask="'alias': 'integer'"
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-3">
          <b-form-group>
            <label>CEP</label>
            <b-form-input
              name="cep"
              v-model="frmConfiguracao.cep"
              data-inputmask="'mask': '99999-999'"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <b-form-group>
            <label>Tipo de negócio<span class="text-danger">*</span>:</label>
            <b-form-select
              :options="sltTipoNegocio"
              v-model="frmConfiguracao.tipoNegocio"
            ></b-form-select>
          </b-form-group>
        </div>

        <div class="col-3">
          <b-form-group>
            <label>Fuso Horário<span class="text-danger">*</span>:</label>
            <b-form-select
              :options="sltFusoHorario"
              v-model="frmConfiguracao.fusoHorario"
            ></b-form-select>
          </b-form-group>
        </div>

        <div class="col-3">
          <b-form-group>
            <label>Franquia de email:</label>
            <b-form-input
              name="franquiaEmail"
              v-model="frmConfiguracao.franquiaEmail"
              data-inputmask="'alias': 'integer'"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>
    </b-form>

    <modal-cadastrar-cliente
      ref="mdlCadastrarCliente"
      :sltTipoNegocio="sltTipoNegocio"
      @cliente:cadastrado="clienteCadastrado"
    ></modal-cadastrar-cliente>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";

import Inputmask from "inputmask";
import { mapGetters } from "vuex";
import ModalCadastrarCliente from "./ModalCadastrarCliente.vue";

export default {
  props: {
    conta: Object
  },

  components: {
    "modal-cadastrar-cliente": ModalCadastrarCliente
  },

  data() {
    return {
      frmConfiguracao: {
        idConta: null,
        idCliente: "",
        nome: "",
        franquiaMinima: 0,
        cep: "",
        tipoNegocio: null,
        fusoHorario: "",
        franquiaEmail: 0
      },
      carregando: true,
      sltTipoNegocio: [],
      sltFusoHorario: [
        {
          text: "UTC -5",
          value: "-5:00"
        },
        {
          text: "UTC -4",
          value: "-4:00"
        },
        {
          text: "UTC -3",
          value: "-3:00"
        },
        {
          text: "UTC -2",
          value: "-2:00"
        }
      ],
      defaultsSelect: {
        cliente: {
          text: "Selecione",
          value: null
        }
      }
    };
  },

  mounted() {
    this.abrir();
  },

  computed: {
    ...mapGetters(["validatorErrors"])
  },

  methods: {
    abrir() {
      const self = this;

      ApiService.post("conta/obter-configuracao", {
        idConta: this.conta.id
      })
        .then(async (response) => {
          const cliente = response.data.data.cliente;
          this.sltTipoNegocio = response.data.data.sltTipoNegocio;
          this.sltCliente = response.data.data.sltCliente;

          this.frmConfiguracao.idConta = this.conta.id;
          this.frmConfiguracao.idCliente = cliente.id;
          this.frmConfiguracao.nome = cliente.nome;
          this.frmConfiguracao.franquiaMinima = cliente.franquia;
          this.frmConfiguracao.cep = cliente.cep;
          this.frmConfiguracao.tipoNegocio = cliente.tipo;
          this.frmConfiguracao.fusoHorario = cliente.fusoHorario;
          this.frmConfiguracao.franquiaEmail = cliente.franquia_email;

          this.selecionarCliente(this.frmConfiguracao.idCliente);

          this.carregando = false;

          await this.$nextTick();

          const frmConfiguracao = this.$refs["frmConfiguracao"];
          Inputmask().mask(frmConfiguracao.querySelectorAll("input"));
        })
        .then(function () {
          self.validacao();

          self.$watch(
            "frmConfiguracao",
            function () {
              self.$emit("aba-alterada", true);
            },
            { deep: true }
          );
        });
    },

    validacao() {
      const frmConfiguracao = this.$refs["frmConfiguracao"];
      const self = this;

      this.fv = formValidation(frmConfiguracao, {
        fields: {
          idCliente: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              blank: {}
            }
          },
          nome: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              blank: {}
            }
          },
          tipoNegocio: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              blank: {}
            }
          }
        },
        plugins: {
          trigger: new Trigger(),
          bootstrap: new Bootstrap()
        }
      });
    },

    salvar() {
      return this.fv.validate().then((status) => {
        if (status == "Valid") {
          ApiService.post("conta/atualizar-configuracao", this.frmConfiguracao)
            .then((res) => {
              this.conta.nome = this.frmConfiguracao.nome;
              this.sltCliente = res.data.data.sltCliente;

              this.selecionarCliente(this.frmConfiguracao.idCliente);

              this.$emit("aba-alterada", false);

              Swal.fire({
                title: "MENSAGEM",
                text: "Atualizado com sucesso!",
                icon: "success",
                heightAuto: false
              });
            })
            .catch(() => {
              for (const error in this.validatorErrors) {
                this.fv
                  .updateValidatorOption(
                    error,
                    "blank",
                    "message",
                    this.validatorErrors[error]
                  )
                  .updateFieldStatus(error, "Invalid", "blank");
              }
            });
        }
      });
    },

    abrirMdlCadastrarCliente() {
      this.$refs["mdlCadastrarCliente"].abrir();
    },

    clienteCadastrado({ cliente, sltCliente }) {
      this.frmConfiguracao.idCliente = cliente.cliente_id;
      this.frmConfiguracao.nome = cliente.cli_nome;
      this.frmConfiguracao.franquiaMinima = cliente.cli_franquia_minima;
      this.frmConfiguracao.cep = cliente.cli_cep;
      this.frmConfiguracao.tipoNegocio = cliente.tipo_negocio_id;
      this.frmConfiguracao.franquiaEmail = cliente.cli_franquia_email;

      this.sltCliente = sltCliente;

      this.selecionarCliente(cliente.cliente_id);
    },

    obterCliente(cliente) {
      this.frmConfiguracao.idCliente = cliente?.id;
      this.frmConfiguracao.nome = cliente?.nome;
      this.frmConfiguracao.franquiaMinima = cliente?.franquia;
      this.frmConfiguracao.cep = cliente?.cep;
      this.frmConfiguracao.tipoNegocio = cliente?.tipo;
      this.frmConfiguracao.franquiaEmail = cliente?.franquia_email;
    },

    selecionarCliente(value) {
      this.sltCliente.every((obj) => {
        if (obj.value == value) {
          this.defaultsSelect.cliente = { ...obj };
        }

        return true;
      });
    }
  }
};
</script>
