<template>
  <div>
    <div class="d-flex justify-content-center" v-if="carregando">
      <v-progress-circular indeterminate size="32" color="#356a7c" width="2"
        ><v-progress-circular
          indeterminate
          size="24"
          color="#ef720f"
          width="2"
          rotate="180"
        ></v-progress-circular
      ></v-progress-circular>
    </div>

    <b-form ref="frmPlano" v-if="!carregando">
      <div class="row">
        <div class="col-md-6" v-if="!this.conta.contaPrincipal">
          <b-form-group>
            <label>Tipo de plano <span class="text-danger">*</span>:</label>
            <b-form-select
              name="tipoPlano"
              :options="sltTipoPlano"
              v-model="frmPlano.tipoPlano"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-6" v-show="frmPlano.tipoPlano == 'POS'">
          <b-form-group>
            <label
              >Limite mensal de SMS <span class="text-danger">*</span>:</label
            >
            <b-form-input
              name="limiteCredito"
              v-model="frmPlano.limiteCredito"
              type="number"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>

      <b-form-group>
        <div class="checkbox-inline">
          <label class="checkbox font-weight-normal">
            <input
              type="checkbox"
              name="permitirMensagensLongas"
              v-model="frmPlano.permitirMensagensLongas"
            />
            <span></span>
            Permitir mensagens longas (acima de 160 caracteres)
          </label>
        </div>
      </b-form-group>

      <div v-show="npsExiste">
        <div>
          <div class="row">
            <div class="col-md-6">
              <b-form-group>
                <label
                  >Prefixo da URL do NPS<span class="text-danger">*</span
                  >:</label
                >
                <b-form-input
                  name="npsPrefixo"
                  v-model="frmPlano.npsPrefixo"
                  disabled
                ></b-form-input>
                <span class="form-text text-muted"
                  >{{ npsProtocolo
                  }}<b>{{ frmPlano.npsPrefixo || conta.login }}</b
                  >.{{ npsURL }}</span
                >
              </b-form-group>
            </div>

            <div class="col-md-6">
              <b-form-group>
                <label
                  >URL de callback<span class="text-danger">*</span>:</label
                >
                <b-form-input
                  name="urlCallback"
                  v-model="frmPlano.urlCallback"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <b-form-group>
                <label>Token do NPS <span class="text-danger">*</span>:</label>
                <b-input-group>
                  <b-form-input
                    name="npsToken"
                    v-model="frmPlano.npsToken"
                    disabled
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button variant="primary" @click="obterTokenNps"
                      >Obter</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
          </div>
        </div>
      </div>

      <button
        type="button"
        class="btn btn-primary btn-block"
        v-if="npsExiste === false && isAdmin"
        @click="criarNPS"
      >
        Criar NPS
      </button>
    </b-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";

import Inputmask from "inputmask";

export default {
  props: {
    conta: Object
  },
  data() {
    return {
      npsExiste: false,
      npsProtocolo: "",
      npsURL: "",
      frmPlano: {
        idConta: null,
        idContaPrincipal: null,
        tipoPlano: "POS",
        limiteCredito: null,
        permitirMensagensLongas: false,
        npsPrefixo: null,
        npsToken: null,
        npsId: null,
        urlCallback: null
      },
      sltTipoPlano: [
        { text: "Pós-pago", value: "POS" },
        { text: "Pré-pago", value: "PRE" }
      ],
      carregando: true
    };
  },

  mounted() {
    this.abrir();
  },

  computed: {
    ...mapGetters(["validatorErrors", "isAdmin"])
  },

  watch: {
    "frmCadastrarConta.tipoPlano": function (value) {
      if (value == "POS") {
        this.fv.enableValidator("limiteCredito", "notEmpty");
      } else {
        this.fv.disableValidator("limiteCredito", "notEmpty");
      }
    }
  },

  methods: {
    obterDadosNps() {
      const self = this;

      ApiService.post("/conta/obter-dados-nps", {
        idConta: this.conta.id
      })
        .then((response) => {
          const nps = response.data.data;

          this.npsExiste = nps.npsExiste;
          this.npsProtocolo = nps.npsProtocolo;
          this.npsURL = nps.npsURL;

          this.frmPlano.npsPrefixo = nps.npsPrefixo;
          this.frmPlano.npsToken = nps.npsToken;
          this.frmPlano.urlCallback = nps.urlCallback;
          this.frmPlano.npsId = nps.npsId;

          this.carregando = false;
        })
        .then(function () {
          self.validacao();

          self.$watch(
            "frmPlano",
            function () {
              self.$emit("aba-alterada", true);
            },
            { deep: true }
          );
        });
    },

    abrir() {
      this.frmPlano.idConta = this.conta.id;
      this.frmPlano.idContaPrincipal = this.conta.contaPrincipal;
      this.frmPlano.tipoPlano = this.conta.tipoPlano;
      this.frmPlano.limiteCredito = this.conta.limiteCredito;
      this.frmPlano.permitirMensagensLongas =
        this.conta.permitirMensagensLongas;

      this.obterDadosNps();
    },

    validacao() {
      const frmPlano = this.$refs["frmPlano"];
      const self = this;

      Inputmask().mask(frmPlano.querySelectorAll("input"));

      this.fv = formValidation(frmPlano, {
        fields: {
          tipoPlano: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              blank: {}
            }
          },
          limiteCredito: {
            validators: {
              notEmpty: {
                enabled: true,
                message: "Campo obrigatório."
              },
              blank: {}
            }
          },
          npsPrefixo: {
            validators: {
              callback: {
                message: "Campo obrigatório.",
                callback: function (input) {
                  if (!self.npsExiste) {
                    return true;
                  }

                  return !!self.frmPlano.npsPrefixo;
                }
              },
              blank: {}
            }
          },
          npsToken: {
            validators: {
              callback: {
                message: "Campo obrigatório.",
                callback: function (input) {
                  if (!self.npsExiste) {
                    return true;
                  }

                  return !!self.frmPlano.npsToken;
                }
              },
              blank: {}
            }
          }
        },
        plugins: {
          trigger: new Trigger(),
          bootstrap: new Bootstrap()
        }
      });
    },

    salvar() {
      this.fv.validate().then((status) => {
        if (status == "Valid") {
          ApiService.post("conta/atualizar", this.frmPlano)
            .then((response) => {
              const conta = response.data.data;
              this.$bus.$emit("conta:atualizada", conta);
              this.$emit("aba-alterada", false);

              Swal.fire({
                title: "MENSAGEM",
                text: "Atualizado com sucesso!",
                icon: "success",
                heightAuto: false
              });
            })
            .catch(() => {
              for (const error in this.validatorErrors) {
                this.fv
                  .updateValidatorOption(
                    error,
                    "blank",
                    "message",
                    this.validatorErrors[error]
                  )
                  .updateFieldStatus(error, "Invalid", "blank");
              }
            });
        }
      });
    },

    obterTokenNps() {
      const self = this;

      Swal.fire({
        title: `Deseja obter um novo token para a conta "${this.conta.login}"?`,
        showCancelButton: true,
        confirmButtonText: "Obter",
        cancelButtonText: "Cancelar",
        customClass: {
          cancelButton: "btn btn-secondary",
          confirmButton: "btn btn-success"
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          ApiService.post("/conta/obter-token-nps", {
            idConta: this.conta.id
          }).then((response) => {
            const nps = response.data.data;

            self.frmPlano.npsToken = nps.token;
            self.frmPlano.npsId = nps.id;
          });
        }
      });
    },

    criarNPS() {
      Swal.fire({
        title: `Confirma criação do NPS para a conta "${this.conta.login}"?`,
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        customClass: {
          cancelButton: "btn btn-secondary",
          confirmButton: "btn btn-success"
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          ApiService.post("/conta/criar-nps", {
            idConta: this.conta.id
          }).then((response) => {
            const nps = response.data.data;

            this.frmPlano.npsToken = nps.token;
            this.frmPlano.npsId = nps.id;

            this.npsExiste = true;

            Swal.fire({
              title: "MENSAGEM",
              text: "NPS criado com sucesso!",
              icon: "success",
              heightAuto: false
            });
          });
        }
      });
    }
  }
};
</script>
