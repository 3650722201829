<template>
  <div :class="{ 'd-inline-flex align-middle mr-2': acao != 'cadastrar' }">
    <template v-if="acao == 'cadastrar'">
      <div class="row">
        <div class="col-12">
          <button
            type="button"
            class="btn btn-sm btn-success font-weight-bold float-right"
            @click.prevent="abrir"
          >
            <i class="fa fa-plus"></i> Cadastrar Unidade
          </button>
        </div>
      </div>
    </template>
    <template v-else>
      <b-button
        variant="light-info"
        size="sm"
        class="btn-icon"
        v-b-tooltip.noninteractive
        title="Editar"
        @click.prevent="abrir"
      >
        <i class="fas fa-edit"></i>
      </b-button>
    </template>

    <b-modal
      ref="mdlFormularioUnidade"
      :title="acao == 'cadastrar' ? 'Cadastrar Unidade' : 'Editar Unidade'"
      @hidden="limpar"
      @shown="validacao"
      @ok="salvar"
      ok-title="Salvar"
      ok-variant="success"
      cancel-title="Cancelar"
      size="lg"
      scrollable
      ignore-enforce-focus-selector=".swal2-container"
    >
      <b-form ref="frmFormularioUnidade">
        <div class="row">
          <div class="col-3">
            <b-form-group>
              <label>Código <span class="text-danger">*</span>:</label>
              <b-form-input
                name="email"
                v-model="frmFormularioUnidade.codigo"
                :disabled="frmFormularioUnidade.id != null"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-9">
            <b-form-group>
              <label>Nome <span class="text-danger">*</span>:</label>
              <b-form-input
                name="nome"
                v-model="frmFormularioUnidade.nome"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <b-form-group>
              <label>CNPJ:</label>
              <b-form-input
                name="cnpj"
                v-model="frmFormularioUnidade.cnpj"
                data-inputmask="'mask': '99.999.999/9999-99', 'placeholder': ''"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-md-3">
            <b-form-group>
              <label>CEP<span class="text-danger">*</span>:</label>
              <b-form-input
                name="cep"
                v-model="frmFormularioUnidade.cep"
                data-inputmask="'mask': '99999-999', 'placeholder': ''"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-md-5">
            <b-form-group>
              <label>Tipo <span class="text-danger">*</span>:</label>
              <b-form-select
                name="tipo"
                :options="sltTiposUnidades"
                v-model="frmFormularioUnidade.tipo"
              ></b-form-select>
            </b-form-group>
          </div>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import Inputmask from "inputmask";

export default {
  props: {
    acao: {
      type: String,
      default: "cadastrar"
    },
    idConta: {
      type: Number,
      default: null
    },
    unidade: {
      type: Object,
      default: null
    },
    tiposUnidades: {
      type: Array,
      default: null
    }
  },

  data() {
    return {
      frmFormularioUnidade: {
        idConta: null,
        id: null,
        codigo: "",
        nome: "",
        cnpj: "",
        cep: "",
        tipo: null
      }
    };
  },

  computed: {
    ...mapGetters(["validatorErrors"]),

    sltTiposUnidades() {
      const tiposUnidades = [
        {
          text: "Selecione",
          value: null
        }
      ];

      for (const obj of this.tiposUnidades) {
        tiposUnidades.push({
          text: obj.tun_nome,
          value: obj.tipo_unidade_id
        });
      }

      return tiposUnidades;
    }
  },

  methods: {
    limpar() {
      this.frmFormularioUnidade.idConta = null;
      this.frmFormularioUnidade.id = null;
      this.frmFormularioUnidade.codigo = "";
      this.frmFormularioUnidade.nome = "";
      this.frmFormularioUnidade.cnpj = "";
      this.frmFormularioUnidade.cep = "";
      this.frmFormularioUnidade.tipo = null;
    },

    abrir() {
      this.limpar();

      this.frmFormularioUnidade.idConta = this.idConta;

      if (this.unidade) {
        this.frmFormularioUnidade.id = this.unidade.und_codigo;
        this.frmFormularioUnidade.codigo = this.unidade.und_codigo;
        this.frmFormularioUnidade.nome = this.unidade.und_nome;
        this.frmFormularioUnidade.cep = this.unidade.und_cep;
        this.frmFormularioUnidade.cnpj = this.unidade.und_cnpj;
        this.frmFormularioUnidade.tipo = this.unidade.tipo_unidade_id;
      }

      this.$refs["mdlFormularioUnidade"].show();
    },

    validacao() {
      const frmFormularioUnidade = this.$refs["frmFormularioUnidade"];
      Inputmask().mask(frmFormularioUnidade.querySelectorAll("input"));

      this.fv = formValidation(frmFormularioUnidade, {
        fields: {
          codigo: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              blank: {}
            }
          },
          nome: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              blank: {}
            }
          },
          cep: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              blank: {}
            }
          },
          tipo: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              blank: {}
            }
          }
        },
        plugins: {
          trigger: new Trigger(),
          bootstrap: new Bootstrap()
        }
      });
    },

    salvar(e) {
      e.preventDefault();

      this.fv.validate().then((status) => {
        if (status == "Valid") {
          ApiService.post("conta/salvar-unidade-nps", this.frmFormularioUnidade)
            .then((response) => {
              const unidades = response.data.data;

              this.$emit("unidade-salva", unidades);
              this.$refs["mdlFormularioUnidade"].hide();

              Swal.fire({
                title: "MENSAGEM",
                text: "Salvo com sucesso!",
                icon: "success",
                heightAuto: false
              });
            })
            .catch(() => {
              for (const error in this.validatorErrors) {
                this.fv
                  .updateValidatorOption(
                    error,
                    "blank",
                    "message",
                    this.validatorErrors[error]
                  )
                  .updateFieldStatus(error, "Invalid", "blank");
              }
            });
        }
      });
    }
  }
};
</script>
