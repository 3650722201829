<template>
  <div>
    <div class="d-flex justify-content-center" v-if="carregando">
      <v-progress-circular indeterminate size="32" color="#356a7c" width="2"
        ><v-progress-circular
          indeterminate
          size="24"
          color="#ef720f"
          width="2"
          rotate="180"
        ></v-progress-circular
      ></v-progress-circular>
    </div>

    <b-form ref="frmRecursos" v-if="!carregando">
      <div v-for="recurso in frmRecursos" :key="recurso.id">
        <div class="d-flex justify-content-between">
          <p>{{ recurso.nome }}</p>
          <InputSwitch v-model="recurso.ativo"></InputSwitch>
        </div>

        <div class="border-bottom mb-5 col-12"></div>
      </div>
    </b-form>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import InputSwitch from "primevue/inputswitch";

export default {
  props: {
    conta: Object
  },

  components: {
    InputSwitch
  },

  data() {
    return {
      frmRecursos: [],
      carregando: true
    };
  },

  mounted() {
    this.abrir();
  },

  methods: {
    abrir() {
      const self = this;
      let recursos = {};
      ApiService.post("conta/obter-configuracao-recursos", {
        idConta: this.conta.id
      })
        .then((response) => {
          recursos = response.data.data;

          recursos.forEach(function (recurso) {
            recurso.ativo = recurso.recurso_ativo == "S";
          });
        })
        .then(function () {
          self.frmRecursos = recursos;

          self.carregando = false;
        })
        .then(function () {
          self.$watch(
            "frmRecursos",
            function () {
              self.$emit("aba-alterada", true);
            },
            { deep: true }
          );
        });
    },

    salvar() {
      ApiService.post("conta/salvar-configuracao-recursos", {
        idConta: this.conta.id,
        recursos: this.frmRecursos
      }).then(() => {
        this.$emit("aba-alterada", false);

        Swal.fire({
          title: "MENSAGEM",
          text: "Atualizado com sucesso!",
          icon: "success",
          heightAuto: false
        }).then((result) => {
          if (result.isConfirmed) {
            this.$emit("recursos-atualizados", false);
          }
        });
      });
    }
  },

  computed: {
    botaoDropdown() {
      return this.tipoBotao === "dropdown";
    }
  }
};
</script>
