<template>
  <div>
    <b-modal
      ref="mdlCadastrarCliente"
      title="Cadastrar Cliente"
      @hidden="limpar"
      @shown="validacao"
      @ok="salvar"
      ok-title="Salvar"
      ok-variant="success"
      cancel-title="Cancelar"
      size="lg"
      scrollable
      ignore-enforce-focus-selector=".swal2-container"
    >
      <b-form ref="frmCadastrarCliente">
        <div class="row">
          <div class="col-6">
            <b-form-group>
              <label>Nome <span class="text-danger">*</span>:</label>
              <b-form-input
                name="nome"
                v-model="frmCadastrarCliente.nome"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-6">
            <b-form-group>
              <label>Email de contato:</label>
              <b-form-input
                name="email"
                v-model="frmCadastrarCliente.email"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-3">
            <b-form-group>
              <label>Franquia mínima<span class="text-danger">*</span>:</label>
              <b-form-input
                name="franquiaMinima"
                v-model="frmCadastrarCliente.franquiaMinima"
                data-inputmask="'alias': 'integer'"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-3">
            <b-form-group>
              <label>CEP</label>
              <b-form-input
                name="cep"
                v-model="frmCadastrarCliente.cep"
                data-inputmask="'mask': '99999-999'"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-6">
            <b-form-group>
              <label>Tipo de negócio<span class="text-danger">*</span>:</label>
              <b-form-select
                :options="sltTipoNegocio"
                v-model="frmCadastrarCliente.tipoNegocio"
              ></b-form-select>
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-3">
            <b-form-group>
              <label
                >Franquia de email<span class="text-danger">*</span>:</label
              >
              <b-form-input
                name="franquiaEmail"
                v-model="frmCadastrarCliente.franquiaEmail"
                data-inputmask="'alias': 'integer'"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";

import Inputmask from "inputmask";

export default {
  props: {
    sltTipoNegocio: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      frmCadastrarCliente: {
        nome: "",
        email: "",
        franquiaMinima: 0,
        cep: "",
        tipoNegocio: null,
        franquiaEmail: 0
      }
    };
  },

  computed: {
    ...mapGetters(["validatorErrors"])
  },

  methods: {
    limpar() {
      this.frmCadastrarCliente.nome = "";
      this.frmCadastrarCliente.email = "";
      this.frmCadastrarCliente.franquiaMinima = 0;
      this.frmCadastrarCliente.cep = "";
      this.frmCadastrarCliente.tipoNegocio = null;
      this.frmCadastrarCliente.franquiaEmail = 0;
    },

    abrir() {
      this.$refs["mdlCadastrarCliente"].show();
    },

    validacao() {
      const frmCadastrarCliente = this.$refs["frmCadastrarCliente"];

      Inputmask().mask(frmCadastrarCliente.querySelectorAll("input"));

      this.fv = formValidation(frmCadastrarCliente, {
        fields: {
          nome: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              blank: {}
            }
          },
          email: {
            validators: {
              emailAddress: {
                message: "O email informado é inválido."
              },
              blank: {}
            }
          },
          franquiaMinima: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              blank: {}
            }
          },
          cep: {
            validators: {
              blank: {}
            }
          },
          tipoNegocio: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              blank: {}
            }
          },
          franquiaEmail: {
            validators: {
              blank: {}
            }
          }
        },
        plugins: {
          trigger: new Trigger(),
          bootstrap: new Bootstrap()
        }
      });
    },

    salvar(e) {
      e.preventDefault();

      this.fv.validate().then((status) => {
        if (status == "Valid") {
          ApiService.post("cliente/salvar", this.frmCadastrarCliente)
            .then((response) => {
              this.$emit("cliente:cadastrado", response.data.data);
              this.$refs["mdlCadastrarCliente"].hide();

              Swal.fire({
                title: "MENSAGEM",
                text: "Salvo com sucesso!",
                icon: "success",
                heightAuto: false
              });
            })
            .catch(() => {
              for (const error in this.validatorErrors) {
                this.fv
                  .updateValidatorOption(
                    error,
                    "blank",
                    "message",
                    this.validatorErrors[error]
                  )
                  .updateFieldStatus(error, "Invalid", "blank");
              }
            });
        }
      });
    }
  }
};
</script>
