var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ 'd-inline-flex align-middle mr-2': !_vm.botaoDropdown }},[(_vm.tipoBotao === 'texto')?[_c('button',{staticClass:"btn btn-sm btn-warning font-weight-bold",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.abrir.apply(null, arguments)}}},[_c('i',{staticClass:"fas fa-cog"}),_vm._v("Configuração ")])]:(_vm.tipoBotao == 'icone')?[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive",modifiers:{"noninteractive":true}}],staticClass:"btn-icon",attrs:{"variant":_vm.variant,"size":"sm","title":"Configuração"},on:{"click":function($event){$event.preventDefault();return _vm.abrir.apply(null, arguments)}}},[_c('i',{staticClass:"fas fa-cog"})])]:[_c('b-dropdown-item',{on:{"click":function($event){$event.preventDefault();return _vm.abrir.apply(null, arguments)}}},[_vm._v("Configuração")])],_c('b-modal',{ref:"mdlConfiguracao",attrs:{"id":"mdlConfiguracaoId","title":"Configuração","ok-title":"Salvar","ok-variant":"success","cancel-title":"Cancelar","size":"xl","scrollable":"","ignore-enforce-focus-selector":".swal2-container, .p-dropdown-panel","no-close-on-esc":"","no-close-on-backdrop":"","body-class":"px-0 pb-2 pt-0","header-class":"border-0"},on:{"ok":_vm.salvar},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
return [_c('button',{staticClass:"btn",class:{
          'btn-secondary': !_vm.existeAlteracao,
          'btn-danger': _vm.existeAlteracao
        },attrs:{"type":"button"},on:{"click":_vm.cancelarAlteracao}},[_vm._v(" "+_vm._s(!_vm.existeAlteracao ? "Fechar" : "Cancelar")+" ")]),_c('button',{staticClass:"btn btn-success",attrs:{"type":"button","disabled":!_vm.existeAlteracao},on:{"click":function($event){return ok()}}},[_vm._v(" Salvar ")])]}}])},[_c('b-tabs',{attrs:{"content-class":"p-4","lazy":"","nav-wrapper-class":"sticky-top bg-white"},on:{"activate-tab":_vm.verificarAlteracaoAba},model:{value:(_vm.abaSelecionada),callback:function ($$v) {_vm.abaSelecionada=$$v},expression:"abaSelecionada"}},[(_vm.isAdmin)?_c('b-tab',{attrs:{"title":"Geral","active":""}},[_c('tab-configuracao',{ref:"abaConfiguracao",attrs:{"conta":_vm.conta},on:{"aba-alterada":_vm.abaAlterada}})],1):_vm._e(),_c('b-tab',{ref:"abaPlano",attrs:{"title":"Plano"}},[_c('tab-plano',{ref:"abaPlano",attrs:{"conta":_vm.conta},on:{"aba-alterada":_vm.abaAlterada}})],1),_c('b-tab',{ref:"abaChat",attrs:{"title":"Chat"}},[_c('tab-chat',{ref:"abaChat",attrs:{"conta":_vm.conta},on:{"aba-alterada":_vm.abaAlterada,"recursos-atualizados":_vm.recursosAtualizados}})],1),(_vm.isAdmin)?_c('b-tab',{attrs:{"title":"Recursos"}},[_c('tab-recursos',{ref:"abaRecursos",attrs:{"conta":_vm.conta},on:{"aba-alterada":_vm.abaAlterada,"recursos-atualizados":_vm.recursosAtualizados}})],1):_vm._e(),(_vm.isAdmin)?_c('b-tab',{attrs:{"title":"Faturamento"}},[_c('tab-faturamento',{ref:"abaFaturamento",attrs:{"conta":_vm.conta,"subconta":!_vm.exibirAbasConta},on:{"aba-alterada":_vm.abaAlterada}})],1):_vm._e(),(_vm.verificarRecursoAtivo('EMAIL') && _vm.isAdmin)?_c('b-tab',{attrs:{"title":"Email/SMTP"}},[_c('tab-email',{ref:"abaEmail",attrs:{"conta":_vm.conta},on:{"aba-alterada":_vm.abaAlterada}})],1):_vm._e(),(_vm.verificarRecursoAtivo('LIVE_CHAT') && _vm.isAdmin)?_c('b-tab',{attrs:{"title":"Atende"}},[_c('tab-live-chat',{ref:"abaLivechat",attrs:{"conta":_vm.conta},on:{"aba-alterada":_vm.abaAlterada}})],1):_vm._e(),(
          (_vm.verificarRecursoAtivo('BI') || _vm.verificarRecursoAtivo('DIZVC')) &&
          _vm.isAdmin
        )?_c('b-tab',{attrs:{"title":"Unidades"}},[_c('tab-unidades-nps',{ref:"abaUnidadesNps",attrs:{"conta":_vm.conta},on:{"aba-alterada":_vm.abaAlterada}})],1):_vm._e()],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }