<template>
  <div :class="{ 'd-inline-flex align-middle mr-2': !botaoDropdown }">
    <template v-if="tipoBotao === 'texto'">
      <button
        type="button"
        class="btn btn-sm btn-warning font-weight-bold"
        @click.prevent="abrir"
      >
        <i class="fas fa-cog"></i>Configuração
      </button>
    </template>
    <template v-else-if="tipoBotao == 'icone'">
      <b-button
        :variant="variant"
        size="sm"
        class="btn-icon"
        v-b-tooltip.noninteractive
        title="Configuração"
        @click.prevent="abrir"
      >
        <i class="fas fa-cog"></i>
      </b-button>
    </template>
    <template v-else>
      <b-dropdown-item @click.prevent="abrir">Configuração</b-dropdown-item>
    </template>

    <b-modal
      ref="mdlConfiguracao"
      id="mdlConfiguracaoId"
      title="Configuração"
      ok-title="Salvar"
      ok-variant="success"
      @ok="salvar"
      cancel-title="Cancelar"
      size="xl"
      scrollable
      ignore-enforce-focus-selector=".swal2-container, .p-dropdown-panel"
      no-close-on-esc
      no-close-on-backdrop
      body-class="px-0 pb-2 pt-0"
      header-class="border-0"
    >
      <b-tabs
        v-model="abaSelecionada"
        content-class="p-4"
        @activate-tab="verificarAlteracaoAba"
        lazy
        nav-wrapper-class="sticky-top bg-white"
      >
        <b-tab title="Geral" active v-if="isAdmin">
          <tab-configuracao
            :conta="conta"
            ref="abaConfiguracao"
            @aba-alterada="abaAlterada"
          ></tab-configuracao>
        </b-tab>

        <b-tab title="Plano" ref="abaPlano">
          <tab-plano
            :conta="conta"
            ref="abaPlano"
            @aba-alterada="abaAlterada"
          ></tab-plano
        ></b-tab>

        <b-tab title="Chat" ref="abaChat">
          <tab-chat
            :conta="conta"
            ref="abaChat"
            @aba-alterada="abaAlterada"
            @recursos-atualizados="recursosAtualizados"
          ></tab-chat>
        </b-tab>

        <b-tab title="Recursos" v-if="isAdmin"
          ><tab-recursos
            :conta="conta"
            ref="abaRecursos"
            @aba-alterada="abaAlterada"
            @recursos-atualizados="recursosAtualizados"
          ></tab-recursos
        ></b-tab>

        <b-tab title="Faturamento" v-if="isAdmin"
          ><tab-faturamento
            :conta="conta"
            :subconta="!exibirAbasConta"
            ref="abaFaturamento"
            @aba-alterada="abaAlterada"
          ></tab-faturamento
        ></b-tab>

        <b-tab
          title="Email/SMTP"
          v-if="verificarRecursoAtivo('EMAIL') && isAdmin"
          ><tab-email
            :conta="conta"
            ref="abaEmail"
            @aba-alterada="abaAlterada"
          ></tab-email
        ></b-tab>

        <b-tab
          title="Atende"
          v-if="verificarRecursoAtivo('LIVE_CHAT') && isAdmin"
          ><tab-live-chat
            :conta="conta"
            ref="abaLivechat"
            @aba-alterada="abaAlterada"
          ></tab-live-chat
        ></b-tab>

        <b-tab
          title="Unidades"
          v-if="
            (verificarRecursoAtivo('BI') || verificarRecursoAtivo('DIZVC')) &&
            isAdmin
          "
          ><tab-unidades-nps
            :conta="conta"
            ref="abaUnidadesNps"
            @aba-alterada="abaAlterada"
          ></tab-unidades-nps
        ></b-tab>
      </b-tabs>

      <template #modal-footer="{ ok }">
        <button
          type="button"
          class="btn"
          :class="{
            'btn-secondary': !existeAlteracao,
            'btn-danger': existeAlteracao
          }"
          @click="cancelarAlteracao"
        >
          {{ !existeAlteracao ? "Fechar" : "Cancelar" }}
        </button>
        <button
          type="button"
          class="btn btn-success"
          @click="ok()"
          :disabled="!existeAlteracao"
        >
          Salvar
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

import Configuracao from "./Configuracao";
import Plano from "./Plano";
import Chat from "./Chat";
import Recursos from "./Recursos";
import Faturamento from "./Faturamento";
import Email from "./Email";
import Livechat from "./Livechat";
import UnidadesNps from "./UnidadesNps";

export default {
  props: {
    conta: Object,
    variant: {
      type: String,
      default: "light-primary"
    },
    tipoBotao: {
      type: String,
      default: "icone"
    },
    exibirAbasConta: {
      type: Boolean,
      default: false
    }
  },

  components: {
    "tab-configuracao": Configuracao,
    "tab-plano": Plano,
    "tab-chat": Chat,
    "tab-recursos": Recursos,
    "tab-faturamento": Faturamento,
    "tab-email": Email,
    "tab-live-chat": Livechat,
    "tab-unidades-nps": UnidadesNps
  },

  data() {
    return {
      abas: ["abaPlano", "abaChat"],
      abaSelecionada: 0,
      existeAlteracao: false,
      recursos: []
    };
  },

  computed: {
    ...mapGetters(["isAdmin"]),
    botaoDropdown() {
      return this.tipoBotao === "dropdown";
    }
  },

  methods: {
    montarAbas() {
      let abas = ["abaPlano", "abaChat"];

      if (this.isAdmin) {
        abas.unshift("abaConfiguracao");
        abas.push("abaRecursos");
        abas.push("abaFaturamento");
      }

      if (this.verificarRecursoAtivo("EMAIL")) {
        abas.push("abaEmail");
      }

      if (this.verificarRecursoAtivo("LIVE_CHAT")) {
        abas.push("abaLivechat");
      }

      if (this.verificarRecursoAtivo("QR_NPS")) {
        abas.push("abaUnidadesNps");
      }

      this.abas = abas;
    },

    async abrir(exibirModal = true) {
      const self = this;
      await ApiService.get(`conta/recurso-ativo/${this.conta.id}`).then(
        (response) => {
          const recursos = response.data.data;

          this.recursos = recursos.map(function (obj) {
            return obj.rec_codigo;
          });

          this.montarAbas();
        }
      );

      if (exibirModal) {
        this.$refs["mdlConfiguracao"].show();
      }
    },

    salvar(e) {
      e.preventDefault();

      const aba = this.abas[this.abaSelecionada];
      this.$refs[aba].salvar();
    },

    verificarAlteracaoAba(currentTab, previousTab, e) {
      if (this.existeAlteracao) {
        e.preventDefault();

        Swal.fire({
          title: "Suas alterações não foram salvas!",
          text: "Existem alterações que ainda não foram salvas, ao continuar estas alterações serão descartadas! Deseja continuar?",
          showCancelButton: true,
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
          customClass: {
            cancelButton: "btn btn-secondary",
            confirmButton: "btn btn-danger"
          },
          buttonsStyling: false
        }).then((result) => {
          if (result.isConfirmed) {
            this.existeAlteracao = false;

            this.abaSelecionada = currentTab;
          }
        });
      }
    },

    cancelarAlteracao(e) {
      if (this.existeAlteracao) {
        e.preventDefault();

        Swal.fire({
          title: "Suas alterações não foram salvas!",
          text: "Existem alterações que ainda não foram salvas, ao continuar estas alterações serão descartadas! Deseja cancelar e sair?",
          showCancelButton: true,
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
          customClass: {
            cancelButton: "btn btn-secondary",
            confirmButton: "btn btn-danger"
          },
          buttonsStyling: false
        }).then((result) => {
          if (result.isConfirmed) {
            this.existeAlteracao = false;

            this.$refs["mdlConfiguracao"].hide();
          }
        });
      } else {
        this.$refs["mdlConfiguracao"].hide();
      }
    },

    abaAlterada(valor) {
      this.existeAlteracao = valor;
    },

    verificarRecursoAtivo(recurso) {
      return this.recursos.includes(recurso);
    },

    recursosAtualizados() {
      this.abrir(false);
    }
  }
};
</script>
