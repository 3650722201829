<template>
  <div>
    <div class="d-flex justify-content-center" v-if="carregando">
      <v-progress-circular indeterminate size="32" color="#356a7c" width="2"
        ><v-progress-circular
          indeterminate
          size="24"
          color="#ef720f"
          width="2"
          rotate="180"
        ></v-progress-circular
      ></v-progress-circular>
    </div>

    <b-form ref="frmFaturamento" v-if="!carregando">
      <p class="font-size-h6 mb-2">DADOS DA INTEGRAÇÃO</p>
      <div class="separator separator-solid mb-6"></div>

      <b-form-group>
        <div class="checkbox-inline">
          <label class="checkbox font-weight-normal">
            <input
              type="checkbox"
              name="faturar"
              v-model="frmFaturamento.faturar"
            />
            <span></span>
            Habilitar faturamento
          </label>

          <label class="checkbox font-weight-normal" v-if="!subconta">
            <input
              type="checkbox"
              name="agrupar_faturamento"
              v-model="frmFaturamento.agruparFaturamento"
            />
            <span></span>
            Agrupar faturamento
          </label>
        </div>
      </b-form-group>

      <div class="row">
        <div class="col-5">
          <b-form-group>
            <label>ID do cliente:</label>
            <b-form-input
              name="idCliente"
              v-model="frmFaturamento.idCliente"
              data-inputmask="'alias': 'integer'"
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-5">
          <b-form-group>
            <label>ID do solicitante:</label>
            <b-form-input
              name="idSolicitante"
              v-model="frmFaturamento.idSolicitante"
              data-inputmask="'alias': 'integer'"
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-2">
          <b-form-group>
            <label>&nbsp;</label>
            <button
              type="button"
              class="btn btn-warning btn-block"
              @click.prevent="limparDadosIntegracao"
            >
              Limpar
            </button>
          </b-form-group>
        </div>
      </div>

      <b-form-group>
        <label>Plano de faturamento padrão:</label>
        <pv-dropdown
          class="form-control"
          optionLabel="text"
          emptyFilterMessage="Nenhum resultado"
          :filter="true"
          :options="planos"
          v-model="defaultDropdown"
          @input="obterPlano"
          appendTo="mdlConfiguracaoId"
          name="idPlano"
        ></pv-dropdown>
      </b-form-group>

      <div class="d-flex justify-content-between mb-2">
        <p class="font-size-h6 mb-0">PLANO POR FAIXA</p>
        <button
          type="button"
          class="btn btn-primary btn-sm"
          @click="adicionarFaixa"
        >
          Adicionar
        </button>
      </div>
      <div class="separator separator-solid mb-6"></div>

      <div
        class="row bg-gray-300 mx-1 mb-2 py-3 rounded-lg"
        v-for="(faixa, i) in faixaPlano"
        :key="i"
      >
        <div class="col-4">
          <b-form-group class="mb-0">
            <label>Faixa:</label>
            <pv-dropdown
              class="form-control"
              optionLabel="text"
              emptyFilterMessage="Nenhum resultado"
              :filter="true"
              :options="faixas"
              v-model="faixaPlano[i].faixa"
              @input="obterFaixaPlano"
              appendTo="mdlConfiguracaoId"
            ></pv-dropdown>
          </b-form-group>
        </div>

        <div class="col-7">
          <b-form-group class="mb-0">
            <label>Plano:</label>
            <pv-dropdown
              class="form-control"
              optionLabel="text"
              emptyFilterMessage="Nenhum resultado"
              :filter="true"
              :options="planos"
              v-model="faixaPlano[i].plano"
              @input="obterFaixaPlano"
              appendTo="mdlConfiguracaoId"
            ></pv-dropdown>
          </b-form-group>
        </div>

        <div class="col-1 d-flex">
          <b-button
            variant="danger"
            size="sm"
            class="btn-icon align-self-center"
            @click="removerFaixaPlano(i)"
          >
            <i class="fas fa-trash"></i>
          </b-button>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";

import Inputmask from "inputmask";

export default {
  props: {
    conta: Object,
    subconta: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      frmFaturamento: {
        idConta: null,
        idCliente: null,
        idSolicitante: null,
        idPlano: null,
        faixas: [],
        faturar: false,
        agruparFaturamento: false
      },
      defaultDropdown: {
        text: "Selecione",
        value: null
      },
      planos: [],
      faixas: [],
      faixaPlano: [],
      carregando: true
    };
  },

  mounted() {
    this.abrir();
  },

  computed: {
    ...mapGetters(["validatorErrors"])
  },

  methods: {
    abrir() {
      const self = this;

      ApiService.post("conta/obter-dados-faturamento", {
        idConta: this.conta.id
      })
        .then((response) => {
          const dados = response.data.data;
          this.planos = dados.planos;

          const faixas = dados.faixas;

          if (faixas) {
            this.faixas = faixas;
          }

          this.frmFaturamento.idConta = this.conta.id;

          const faturar = dados.faturar;
          this.frmFaturamento.faturar = faturar == "S";

          const integracao = dados.integracao;
          if (integracao) {
            this.frmFaturamento.idCliente = integracao.id_cliente;
            this.frmFaturamento.idSolicitante = integracao.id_solicitante;
            this.frmFaturamento.idPlano = integracao.id_plano;
            this.frmFaturamento.agruparFaturamento =
              integracao.agrupar_faturamento == "S";
          }

          this.defaultDropdown = dados.planoPadrao;
          this.faixaPlano = dados.faixasPlano;

          this.obterFaixaPlano();

          this.carregando = false;
        })
        .then(function () {
          self.validacao();

          self.$watch(
            "frmFaturamento",
            function () {
              self.$emit("aba-alterada", true);
            },
            { deep: true }
          );
        });
    },

    validacao() {
      const frmFaturamento = this.$refs["frmFaturamento"];
      const self = this;
      Inputmask().mask(frmFaturamento.querySelectorAll("input"));
      this.fv = formValidation(frmFaturamento, {
        fields: {
          idCliente: {
            validators: {
              // notEmpty: {
              //   message: "Campo obrigatório."
              // },
              blank: {}
            }
          },
          idSolicitante: {
            validators: {
              // notEmpty: {
              //   message: "Campo obrigatório."
              // },
              blank: {}
            }
          },
          idPlano: {
            validators: {
              // callback: {
              //   callback: function () {
              //     if (self.frmFaturamento.idPlano) {
              //       return true;
              //     }
              //     return false;
              //   },
              //   message: "Campo obrigatório."
              // },
              blank: {}
            }
          }
        },
        plugins: {
          trigger: new Trigger(),
          bootstrap: new Bootstrap()
        }
      });
    },

    salvar() {
      return this.fv.validate().then((status) => {
        if (status == "Valid") {
          ApiService.post("conta/salvar-dados-faturamento", this.frmFaturamento)
            .then(() => {
              this.$emit("aba-alterada", false);

              Swal.fire({
                title: "MENSAGEM",
                text: "Salvo com sucesso!",
                icon: "success",
                heightAuto: false
              });
            })
            .catch(() => {
              for (const error in this.validatorErrors) {
                this.fv
                  .updateValidatorOption(
                    error,
                    "blank",
                    "message",
                    this.validatorErrors[error]
                  )
                  .updateFieldStatus(error, "Invalid", "blank");
              }
            });
        }
      });
    },

    obterPlano(obj) {
      this.frmFaturamento.idPlano = obj ? obj.value : null;
    },

    adicionarFaixa() {
      this.faixaPlano.push({
        faixa: {
          text: "Selecione",
          value: null
        },
        plano: {
          text: "Selecione",
          value: null
        }
      });
    },

    obterFaixaPlano() {
      const self = this;
      self.frmFaturamento.faixas = [];
      this.faixaPlano.forEach(function (obj) {
        self.frmFaturamento.faixas.push({
          faixa: obj ? obj.faixa.value : null,
          plano: obj ? obj.plano.value : null
        });
      });
    },

    removerFaixaPlano(index) {
      this.faixaPlano.splice(index, 1);
      this.obterFaixaPlano();
    },

    limparDadosIntegracao() {
      this.frmFaturamento.idCliente = null;
      this.frmFaturamento.idSolicitante = null;
      this.frmFaturamento.idPlano = null;
      this.defaultDropdown = {
        text: "Selecione",
        value: null
      };
    }
  }
};
</script>
