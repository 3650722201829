<template>
  <div
    v-bind:class="{
      'bg-warning-o-20 p-6 rounded': verificarTipoConta(tipoConta)
    }"
  >
    <div
      class="alert alert-secondary mb-4 bg-light"
      role="alert"
      v-if="paginacao.total > 0 && !verificarTipoConta(tipoConta)"
    >
      <div class="row">
        <div class="col-md-12 text-right">
          Exibindo de
          {{ (paginacao.paginaAtual - 1) * paginacao.porPagina + 1 }} a
          {{
            Math.min(
              paginacao.paginaAtual * paginacao.porPagina,
              paginacao.total
            )
          }}
          de um total de {{ paginacao.total }} registros.
        </div>
      </div>
    </div>

    <b-table
      hover
      small
      show-empty
      :fields="cabecalho"
      :items="contas"
      sort-by="login"
      :sort-desc="false"
      :current-page="paginacao.paginaAtual"
      :per-page="paginacao.porPagina"
    >
      <template #empty>
        <p class="text-center mb-0">Nenhum registro encontrado.</p>
      </template>

      <template #cell(login)="row">
        <span v-b-tooltip.html="obterInformacoesConta(row.item)">{{
          row.item.login
        }}</span>

        <i
          class="fas fa-certificate icon-nm ml-1"
          :class="obterCorMaquinaSenderzap(row.item.maquinaSenderzap)"
          v-b-tooltip.noninteractive
          :title="obterNomeMaquinaSenderzap(row.item.maquinaSenderzap)"
          v-if="isAdmin"
        ></i>

        <i
          class="fas fa-circle icon-nm ml-1"
          :class="obterCorStatus(row.item.status)"
          v-if="isAdmin"
        ></i>
      </template>

      <template #cell(dataUltimoEnvio)="row">
        <span
          v-b-tooltip.noninteractive
          :title="obterDataUltimoEnvioFormatada(row.item.dataUltimoEnvio)"
          >{{ obterDiasUltimoEnvio(row.item.dataUltimoEnvio) }}</span
        >
      </template>

      <template #row-details="row">
        <gerenciar-conta
          :contas="row.item.subcontas"
          tipoConta="subconta"
          v-if="!verificarTipoConta(row.item.tipoConta)"
          :paginacao="{
            paginaAtual: 1,
            porPagina: 0,
            total: 0
          }"
        ></gerenciar-conta>
      </template>

      <template #cell(actions)="row">
        <b-button
          :variant="corBotoesAcao(row.item.tipoConta)"
          size="sm"
          class="btn-icon mr-2"
          v-b-tooltip.noninteractive
          title="Contas"
          @click="row.toggleDetails"
          v-if="
            !verificarTipoConta(row.item.tipoConta) &&
            row.item.subcontas.length > 0
          "
        >
          <i class="fas fa-address-card"></i>
        </b-button>

        <modal-qrCode
          :conta="row.item"
          :exibirBotao="true"
          :variant="corBotoesAcao(row.item.tipoConta)"
          v-if="row.item.chatAtivo"
        ></modal-qrCode>

        <modal-usuarios
          :conta="row.item"
          :variant="corBotoesAcao(row.item.tipoConta)"
          v-if="isAdmin || hasPermission('listar-usuario')"
        ></modal-usuarios>

        <b-dropdown
          no-caret
          :variant="corBotoesAcao(row.item.tipoConta)"
          size="sm"
          toggle-class="btn-icon"
          dropleft
        >
          <template #button-content>
            <i class="fas fa-ellipsis-v p-0"></i>
          </template>

          <modal-adicionar-conta
            @conta:cadastrada="contaCadastrada"
            tipoBotao="dropdown"
            :contaPrincipal="row.item"
            v-if="!verificarTipoConta(row.item.tipoConta)"
          ></modal-adicionar-conta>

          <modal-configuracao
            :conta="row.item"
            tipoBotao="dropdown"
            :exibirAbasConta="!verificarTipoConta(row.item.tipoConta)"
          >
          </modal-configuracao>

          <modal-enviar-manual :conta="row.item" tipoBotao="dropdown">
          </modal-enviar-manual>

          <modal-alterar-senha
            :id="row.item.id"
            url="conta/alterarSenha"
            titulo="Alterar Senha da Conta"
            :exigirSenhaAtual="false"
            @senha:atualizada="senhaContaAtualizada"
            tipoBotao="dropdown"
          ></modal-alterar-senha>

          <template v-if="isAdmin">
            <b-dropdown-item
              v-if="row.item.status == 'A'"
              @click.prevent="alterarStatusConta('bloquear', row.item.id)"
              >Bloquear</b-dropdown-item
            >
            <b-dropdown-item
              v-else
              @click.prevent="alterarStatusConta('desbloquear', row.item.id)"
              >Desbloquear</b-dropdown-item
            >
          </template>

          <template>
            <b-dropdown-item @click.prevent="reiniciarWhatsapp(row.item)"
              >Reiniciar WhatsApp</b-dropdown-item
            >
          </template>

          <template>
            <b-dropdown-item @click.prevent="logoutWhatsapp(row.item)"
              >Sair do WhatsApp</b-dropdown-item
            >
          </template>
        </b-dropdown>
      </template>
    </b-table>

    <div
      class="row"
      v-if="paginacao.total > 0 && !verificarTipoConta(tipoConta)"
    >
      <div class="col-md-4">
        <b-form-group>
          <b-form-select
            :options="sltRegistrosPorPagina"
            v-model="paginacao.porPagina"
          ></b-form-select>
        </b-form-group>
      </div>
      <div class="col-md-8">
        <b-pagination
          :total-rows="paginacao.total"
          v-model="paginacao.paginaAtual"
          :per-page="paginacao.porPagina"
          align="fill"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>

<style scoped>
/deep/ .table-hover tbody tr.b-table-details:hover,
/deep/ .table-hover tbody tr.b-table-details:hover thead tr:hover {
  background-color: unset !important;
}
</style>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

import ModalAlterarSenha from "@/view/components/ModalAlterarSenha";
import ModalEnviarManual from "./ModalEnviarManual";
import ModalUsuarios from "./ModalUsuarios";
import ModalAdicionarConta from "./ModalAdicionarConta";
import ModalConfiguracao from "./ModalConfiguracao.vue";

export default {
  name: "gerenciar-conta",
  props: {
    contas: {
      type: Array,
      default() {
        return [];
      }
    },
    tipoConta: {
      type: String,
      default: "conta"
    },
    paginacao: {
      type: Object,
      default() {
        return {
          paginaAtual: 1,
          porPagina: 20,
          total: 0
        };
      }
    }
  },

  data() {
    return {
      sltRegistrosPorPagina: [
        {
          value: 20,
          text: "Exibir 20 registros por página"
        },
        {
          value: 50,
          text: "Exibir 50 registros por página"
        },
        {
          value: 100,
          text: "Exibir 100 registros por página"
        }
      ]
    };
  },

  components: {
    "modal-alterar-senha": ModalAlterarSenha,
    "modal-enviar-manual": ModalEnviarManual,
    "modal-configuracao": ModalConfiguracao,
    "modal-usuarios": ModalUsuarios,
    "modal-adicionar-conta": ModalAdicionarConta,
    "gerenciar-conta": () => import("./GerenciarConta")
  },

  computed: {
    ...mapGetters(["isAdmin", "hasPermission"]),

    cabecalho() {
      const cabecalho = [
        {
          key: "login",
          label: "Conta",
          sortable: true,
          class: "align-middle"
        },
        {
          key: "nome",
          label: "Cliente",
          sortable: true,
          class: "align-middle"
        },
        {
          key: "tipoPlano",
          label: "Plano",
          sortable: true,
          class: "align-middle text-center"
        },
        {
          key: "dataUltimoEnvio",
          label: "Dias",
          sortable: true,
          class: "align-middle text-center"
        },
        {
          key: "limiteCredito",
          label: "Lim. Mensal",
          sortable: true,
          class: "align-middle text-center"
        },
        {
          key: "credito",
          label: "Créd. Atual",
          sortable: true,
          class: "align-middle text-center"
        },
        {
          key: "consumo",
          label: "Consumo",
          sortable: true,
          class: "align-middle text-center"
        },
        {
          key: "saldo",
          label: "Saldo",
          sortable: true,
          class: "align-middle text-center"
        }
      ];

      if (this.tipoConta == "conta") {
        cabecalho.unshift({
          key: "sigla",
          label: "Sigla",
          sortable: true,
          class: "align-middle"
        });
      } else {
        cabecalho.splice(2, 0, {
          key: "nomeIdentificacao",
          label: "Nome Identificação",
          sortable: true,
          class: "align-middle"
        });
      }

      if (this.isAdmin || this.hasPermission("gerenciar-conta")) {
        cabecalho.push({
          key: "actions",
          label: "Ações",
          sortable: false,
          class: "align-middle text-center"
        });
      }

      return cabecalho;
    }
  },

  mounted() {
    const self = this;
    this.$bus.$on("conta:atualizada", function (conta) {
      self.contaAtualizada(conta);
    });
  },

  methods: {
    reiniciarWhatsapp(conta) {
      const payload = {
        conta_id: conta.id
      };
      Swal.fire({
        title: "Deseja realmente reiniciar o Whatsapp?",
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        customClass: {
          cancelButton: "btn btn-secondary",
          confirmButton: "btn btn-success"
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          this.reiniciarWhatsappConfirmado(payload);
        }
      });
    },
    reiniciarWhatsappConfirmado(payload) {
      ApiService.post("/conta/reiniciar-whatsapp", payload)
        .then(() => {
          Swal.fire({
            title: "Sucesso!",
            text: "O Whatsapp foi reiniciado com sucesso!",
            icon: "success",
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn btn-primary"
            },
            buttonsStyling: false
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "Erro!",
            text: "O Whatsapp não foi reiniciado!",
            icon: "error",
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn btn-primary"
            },
            buttonsStyling: false
          });
        });
    },
    logoutWhatsapp(conta) {
      const payload = {
        conta_id: conta.id
      };
      Swal.fire({
        title: "Deseja realmente sair do Whatsapp?",
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        customClass: {
          cancelButton: "btn btn-secondary",
          confirmButton: "btn btn-success"
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          this.logoutWhatsappConfirmado(payload);
        }
      });
    },
    logoutWhatsappConfirmado(payload) {
      ApiService.post("/conta/logout-whatsapp", payload)
        .then(() => {
          Swal.fire({
            title: "Sucesso!",
            text: "O logout foi realizado com sucesso!",
            icon: "success",
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn btn-primary"
            },
            buttonsStyling: false
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "Erro!",
            text: "Não foi possível realizar o logout!",
            icon: "error",
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn btn-primary"
            },
            buttonsStyling: false
          });
        });
    },
    senhaContaAtualizada(contaAtualizada) {
      const indexConta = this.contas.findIndex(
        (conta) => conta.id === contaAtualizada.id
      );

      this.contas.splice(indexConta, 1, contaAtualizada);
    },

    contaAtualizada(contaAtualizada) {
      if (contaAtualizada.contaPrincipal) {
        const indexConta = this.contas.findIndex(
          (conta) => conta.id === contaAtualizada.contaPrincipal
        );

        const indexSubconta = this.contas[indexConta].subcontas.findIndex(
          (conta) => conta.id === contaAtualizada.id
        );

        this.contas[indexConta].subcontas.splice(
          indexSubconta,
          1,
          contaAtualizada
        );
      } else {
        const indexConta = this.contas.findIndex(
          (conta) => conta.id === contaAtualizada.id
        );

        this.contas.splice(indexConta, 1, contaAtualizada);
      }
    },

    verificarTipoConta(tipoConta) {
      return tipoConta === "subconta";
    },

    corBotoesAcao(tipoConta) {
      return tipoConta === "subconta" ? "light-primary" : "light-info";
    },

    contaCadastrada(contaCadastrada) {
      if (contaCadastrada.tipoConta === "subconta") {
        const indexConta = this.contas.findIndex(
          (conta) => conta.id === contaCadastrada.contaPrincipal
        );

        this.contas[indexConta].subcontas.push(contaCadastrada);
      } else {
        this.contas.push(contaCadastrada);
      }
    },

    obterDiasUltimoEnvio(ultimoEnvio) {
      const dias = ultimoEnvio
        ? moment().diff(moment(ultimoEnvio), "days")
        : "-";
      return dias;
    },

    obterDataUltimoEnvioFormatada(ultimoEnvio) {
      return ultimoEnvio
        ? "Último envio: " + moment(ultimoEnvio).format("DD/MM/YYYY HH:mm:ss")
        : "Último envio: Nenhum";
    },

    obterCorMaquinaSenderzap(maquina) {
      switch (maquina) {
        case "senderzap1":
          return { "text-warning": true };
        default:
          return { "text-info": true };
      }
    },

    obterCorStatus(status) {
      if (status == "A") {
        return "text-success";
      }

      return "text-danger";
    },

    obterNomeMaquinaSenderzap(maquina) {
      switch (maquina) {
        case "senderzap1":
          return "Senderzap 1 Bumblebee";
        default:
          return "Senderzap 0 C-3PO";
      }
    },

    obterInformacoesConta(conta) {
      let info = `<b>Código da conta:</b> ${conta.id}`;
      if (this.isAdmin && conta.codigoIntegracao && conta.codigoSolicitante) {
        info += `
          <br>
          <b>Código de integração:</b> ${conta.codigoIntegracao}
          <br>
          <b>Código do solicitante:</b> ${conta.codigoSolicitante}
        `;
      }

      return info;
    },

    alterarStatusConta(status, idConta) {
      const self = this;

      const confStatus = {
        bloquear: {
          textoAlerta: "Deseja realmente bloquear esta conta?",
          textoBotao: "Bloquear",
          corBotao: "btn-danger",
          rota: "bloquear",
          mensagem: "Conta bloqueada com sucesso"
        },
        desbloquear: {
          textoAlerta: "Deseja realmente desbloquear esta conta?",
          textoBotao: "Desbloquear",
          corBotao: "btn-success",
          rota: "desbloquear",
          mensagem: "Conta desbloqueada com sucesso"
        }
      };

      Swal.fire({
        title: confStatus[status].textoAlerta,
        showCancelButton: true,
        confirmButtonText: confStatus[status].textoBotao,
        cancelButtonText: "Cancelar",
        customClass: {
          cancelButton: "btn btn-secondary",
          confirmButton: `btn ${confStatus[status].corBotao}`
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          ApiService.get(
            `conta/status/${confStatus[status].rota}/${idConta}`
          ).then((response) => {
            const conta = response.data.data;

            self.contaAtualizada(conta);

            Swal.fire({
              title: "MENSAGEM",
              text: confStatus[status].mensagem,
              icon: "success",
              heightAuto: false
            });
          });
        }
      });
    }
  }
};
</script>
